import styled from 'styled-components';

type StyledButtonProps = {
  isActive: boolean;
};

type ButtonWrapperProps = {
  isOpen: boolean;
};

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  position: absolute;
  right: 15px;
  top: ${(props) => (props.isOpen ? '15px' : 'auto')};
`;

export const StyledButton = styled.button<StyledButtonProps>`
  color: black;
  background: none;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;

  transition:
    color 0.1s ease,
    transform 0.1s ease;

  &:hover {
    color: black;
    text-decoration: underline;
  }
  &:focus {
    color: #af9483;
    text-decoration: underline;
  }
  font-weight: ${(props) => props.isActive && '900'};
`;
