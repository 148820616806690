import styled from 'styled-components';
import mountainsImage from '../../../assets/images/mountains.webp';
import forestImage from '../../../assets/images/forest.webp';

interface ProjectContainerProps {
  background: 'mountains' | 'forest';
}

export const ProcjectBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  position: relative;
`;

export const ProjectContainer = styled.div<ProjectContainerProps>`
  display: flex;
  height: 600px;
  width: 100vw;
  position: relative;
  background-image: ${(props) =>
    props.background === 'mountains'
      ? `url(${mountainsImage})`
      : `url(${forestImage})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 840px) {
    margin-top: 120px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  margin: 0 auto;
  @media (max-width: 840px) {
    display: flex;
    justify-content: start;
    flex-direction: column;
  }
`;

export const ProjectTitle = styled.h2`
  color: black;
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 0.1em;
`;

export const ProjectContent = styled.p`
  color: black;
  margin-top: 30px;
`;

export const Subtitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
`;

export const DescriptionContainer = styled.div`
  flex: 1.2;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
  @media (max-width: 1200px) {
    flex: 1.6;
  }
`;
export const InnerDescriptionContainer = styled.div`
  margin: 0 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 840px) {
    margin: 0 20px;
  }
`;

export const TitleBox = styled.div`
  flex: 1;
  @media (max-width: 1200px) {
    flex: 0.4;
  }
  @media (max-width: 840px) {
    display: none;
  }
`;

export const ButtonStyled = styled.button`
  margin-top: 30px;
  font-weight: 900;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  box-shadow: 5px 5px 30px 1px rgba(0, 0, 0, 0.2);
  transition:
    background-color 0.1s ease,
    transform 0.1s ease;

  &:hover {
    background-color: #af9483;
  }
  &:focus {
    background-color: #af9483;
  }
`;
