import React from 'react';
import {
  ContactContainer,
  ContentContainer,
  Content,
  Title,
  Text,
  ContactContentContainer,
  ChildDiv,
  // FieldStyled,
  // TextareaStyled,
  // FormStyled,
  // ButtonStyled,
  TextBold,
} from './Contact.styles';
import { useTranslation } from 'react-i18next';

// import { Formik, FormikHelpers } from 'formik';

// interface Values {
//   fullname: string;
//   email: string;
//   phone: string;
//   message: string;
//   toggle: boolean;
// }

export const Contact = () => {
  const { t } = useTranslation();
  return (
    <ContactContainer>
      <ContentContainer>
        <Content>
          <ContactContentContainer>
            <ChildDiv>
              <Title>{t('contact.title')}</Title>
              <Text>{t('contact.description')}</Text>
              <TextBold>{t('contact.email-address')}</TextBold>
            </ChildDiv>
            {/* <ChildDiv>
              <Formik
                initialValues={{
                  fullname: '',
                  email: '',
                  phone: '',
                  message: '',
                  toggle: false,
                }}
                onSubmit={(
                  values: Values,
                  { setSubmitting }: FormikHelpers<Values>,
                ) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 500);
                }}
              >
                <FormStyled>
                  <FieldStyled
                    id="fullname"
                    name="fullname"
                    placeholder="Your fullname/Company name"
                  />

                  <FieldStyled
                    id="email"
                    name="email"
                    placeholder="Your e-mail address"
                    type="email"
                  />

                  <FieldStyled
                    id="phone"
                    name="phone"
                    placeholder="Your phone number"
                  />
                  <TextareaStyled
                    id="message"
                    name="message"
                    placeholder="Message"
                    as="textarea"
                    rows="7"
                  />

                  <ButtonStyled type="submit">Sent</ButtonStyled>
                </FormStyled>
              </Formik>
            </ChildDiv> */}
          </ContactContentContainer>
        </Content>
      </ContentContainer>
    </ContactContainer>
  );
};
