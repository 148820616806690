import styled from 'styled-components';
import { Link } from 'react-router-dom';

type NavLinkProps = {
  isActive: boolean;
};

export const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  color: black;
  gap: 40px;
  @media (max-width: 700px) {
    margin-left: 20px;
    justify-content: start;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const NavLink = styled(Link)<NavLinkProps>`
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  transition:
    color 0.1s ease,
    transform 0.1s ease;

  &:hover {
    color: black;
    text-decoration: underline;
  }
  &:focus {
    color: #af9483;
    text-decoration: underline;
  }
  color: ${(props) => props.isActive && 'color: #af9483'};
  text-decoration: ${(props) => props.isActive && 'underline'};
`;
