import styled from 'styled-components';

export const FooterContainer = styled.div`
  flex-shrink: 0;
  background-color: white;
  color: white;
`;

export const FooterContent = styled.div`
  display: flex;
  height: 200px;
  justify-content: space-around;
  background-color: black;
  align-items: center;
  line-height: 30px;
  font-size: 14px;
  @media (max-width: 840px) {
    height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
    gap: 10px;
  }
`;

export const CreatedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  font-weight: 200;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: black;
`;

export const TextBold = styled.p`
  font-weight: 600;
`;

export const ButtonStyled = styled.button`
  font-weight: 300;
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #af9483;
    text-decoration: underline;
  }
  &:focus {
    color: #af9483;
    text-decoration: underline;
  }
`;

// export const StyledNewsletter = styled.iframe`
//   width: 580px;
//   height: 85vh;
//   border: none;
//   display: flex;
//   align-items: center;
//   margin: auto;
// `;

export const ButtonStyledCoffee = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  color: white;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  padding: 10px 20px 10px 15px;
  border-radius: 5px;
  family-font: Cookie;

  &:hover {
    color: #af9483;
    text-decoration: underline;
  }
  &:focus {
    color: #af9483;
    text-decoration: underline;
  }
`;

export const StyledImg = styled.img`
  height: 36px;
  width: auto;
  margin: 10px;
`;
