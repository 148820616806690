import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  gap: 16px;
  padding: 30px 0 30px 0;
  @media (min-width: 840px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledConatinerForNewsletterDescription = styled.div`
  margin: 15px;
`;

export const Box = styled.div`
  max-width: 500px;
  min-width: 250px;
  @media (max-width: 840px) {
    max-width: 760px;
    min-width: 250px;
  }
`;

export const TitleStyled = styled.h2`
  font-size: 24px;
  margin-bottom: 8px;
  color: #333;
`;

export const DescriptionStyled = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
  color: #555;
  margin-bottom: 12px;
`;

export const AdditionalInfoStyled = styled.p`
  font-size: 12px;
  line-height: 1.3em;
  color: #777;
  margin-bottom: 8px;
`;

export const StyledConatinerForNewsletter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 450px; /* Compensate for the scale of 0.9 (500px * 0.9 = 450px) */
  width: 100%;
  overflow: hidden;
  @media (max-width: 840px) {
    max-width: 684px; /* Compensate for the scale of 0.9 (760px * 0.9 = 684px) */
  }
`;

export const StyledNewsletter = styled.iframe`
  border: none;
  width: 100%;
  height: 400px;

  overflow: hidden;
`;
