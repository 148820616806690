import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Content,
  ContentContainer,
  MainContainer,
  Title,
} from './EmailConfirmation.styles';
import { useTranslation } from 'react-i18next';

const EmailConfirmation = () => {
  const [status, setStatus] = useState('Confirming...');
  const location = useLocation();
  const { t } = useTranslation();

  const confirmParticipant = async (token: string) => {
    try {
      const response = await axios.post(
        '/.netlify/functions/validate-confirmation-token',
        {
          token: token,
        },
      );
      console.log('Confirmation response:', response.data);
      setStatus(response.data.message);
    } catch (error) {
      console.error('Error confirming participant:', error);
      setStatus('Failed to confirm participant.');
    }
  };

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      confirmParticipant(token);
    } else {
      setStatus('No token provided.');
    }
  }, [location]);

  return (
    <MainContainer>
      <ContentContainer>
        <Title>{status}</Title>
        <Content>{t('confirmation.next-move')}</Content>
        <Content>{t('confirmation.confirm')}</Content>
      </ContentContainer>
    </MainContainer>
  );
};

export default EmailConfirmation;
