import React from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonWrapper, StyledButton } from './LanguageSwitcher.styles';

interface LanguageSwitcherProps {
  isOpen: boolean;
}

interface LanguageDict {
  [key: string]: { nativeName: string };
}

const lngs: LanguageDict = {
  en: { nativeName: 'EN' },
  pl: { nativeName: 'PL' },
};

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  isOpen,
}) => {
  const { i18n } = useTranslation();

  return (
    <ButtonWrapper isOpen={isOpen}>
      {Object.keys(lngs).map((lng, index, array) => (
        <React.Fragment key={lng}>
          <StyledButton
            isActive={i18n.resolvedLanguage === lng}
            type="submit"
            onClick={() => i18n.changeLanguage(lng)}
          >
            {lngs[lng].nativeName}
          </StyledButton>
          {index < array.length - 1 && (
            <span style={{ margin: '0 10px' }}>|</span>
          )}
        </React.Fragment>
      ))}
    </ButtonWrapper>
  );
};
