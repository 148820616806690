import React, { useLayoutEffect } from 'react';
import {
  PageContainer,
  ContentContainer,
  Content,
  Title,
  Text,
  ButtonStyled,
} from './Hiking.styles';
// import { Newsletter } from '../../home/Newsletter';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Hiking = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <ContentContainer background="tatry2">
        <Content>
          <Title>{t('hiking.tatry2')}</Title>
          <Text>{t('hiking.destination-tatry2')}</Text>
          <Text>{t('hiking.date-tatry2')}</Text>
          <ButtonStyled
            type="button"
            onClick={() => navigate('/projects/hiking/tatry2')}
            disabled
          >
            {t('button.comming-soon')}
          </ButtonStyled>
        </Content>
      </ContentContainer>
      <ContentContainer background="madera">
        <Content>
          <Title>{t('hiking.madera')}</Title>
          <Text>{t('hiking.destination-madera')}</Text>
          <Text>{t('hiking.subtitle-madera')}</Text>
          <Text>{t('hiking.date-madera')}</Text>

          <ButtonStyled
            type="button"
            onClick={() => navigate('/projects/hiking/madera')}
          >
            {t('button.read-more')}
          </ButtonStyled>
        </Content>
      </ContentContainer>
      <ContentContainer background="malaga">
        <Content>
          <Title>{t('hiking.malaga')}</Title>
          <Text>{t('hiking.destination-malaga')}</Text>
          <Text>{t('hiking.date-malaga')}</Text>
          <ButtonStyled
            type="button"
            onClick={() => navigate('/projects/hiking/malaga')}
          >
            {t('button.read-more')}
          </ButtonStyled>
        </Content>
      </ContentContainer>
      <ContentContainer background="tatry">
        <Content>
          <Title>{t('hiking.tatry')}</Title>
          <Text>{t('hiking.destination-tatry')}</Text>
          <Text>{t('hiking.date-tatry')}</Text>
          <ButtonStyled
            type="button"
            onClick={() => navigate('/projects/hiking/tatry')}
          >
            {t('button.read-more')}
          </ButtonStyled>
        </Content>
      </ContentContainer>

      {/* <Newsletter /> */}
    </PageContainer>
  );
};
