import React from 'react';
import {
  HomeContainer,
  TitleContainer,
  ContentContainer,
  IdeaTitle,
  IdeaContent,
  Subtitle,
  DescriptionContainer,
  TitleBox,
} from './Home.styles';
import logoIconBasic from '../../../assets/images/logo_basic.png';
import { useTranslation } from 'react-i18next';

export const Home = () => {
  const { t } = useTranslation();
  return (
    <div>
      <HomeContainer>
        <ContentContainer>
          <TitleBox>
            <img src={logoIconBasic} alt="logo" height="96" />
            <TitleContainer>HIKE MATTERS</TitleContainer>
            <Subtitle>AIR UP YOUR HEAD</Subtitle>
          </TitleBox>

          <DescriptionContainer>
            <IdeaTitle>{t('home.title')}</IdeaTitle>
            <IdeaContent>{t('home.description')}</IdeaContent>
          </DescriptionContainer>
        </ContentContainer>
      </HomeContainer>
    </div>
  );
};
