import React from 'react';

import { StyledGallery, ImageWrapper, CroppedImage } from './Hikes.styles';
import { imagesMalaga } from './images';

export const PhotoGalleryMalaga = () => {
  return (
    <div>
      <StyledGallery>
        {imagesMalaga.map((src, index) => (
          <ImageWrapper key={index}>
            <CroppedImage src={src} />
          </ImageWrapper>
        ))}
      </StyledGallery>
    </div>
  );
};

export default PhotoGalleryMalaga;
