import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 70%;
  margin: 90px auto;
  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const Title = styled.h2`
  color: black;
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0 20px 0 20px;
`;

export const ParagrafTitle = styled.h3`
  color: black;
  font-size: 1em;
  font-weight: 900;
  margin: 40px 20px 0 20px;
`;

export const Content = styled.p`
  color: black;
  line-height: 1.8em;
  margin: 20px 20px 0 20px;
  font-size: 0.9rem;
`;

export const StyledList = styled.ol`
  margin: 0 0 15px 60px;
  font-size: 12px;
`;
