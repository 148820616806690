import styled from 'styled-components';

export const StyledText = styled.p`
  color: white;
  &:hover {
    color: #af9483;
  }
`;

export const StyledImg = styled.img`
  height: 24px;
  width: auto;
  margin: 10px;
  &:hover {
    outline: 2px solid white;
    outline-offset: 3px;
    border-radius: 5px;
  }
`;
