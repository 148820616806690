import styled from 'styled-components';

export const LogoContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 10;

  @media (max-width: 700px) {
    top: 45px;
  }
`;
