import React, { useLayoutEffect } from 'react';
import {
  Content,
  ContentContainer,
  PageContainer,
  Title,
  BoldText,
  Text,
  StyledSubtitle,
  StyledCallToAction,
  StyledList,
  StyledLi,
} from './Hikes.styles';
import { useTranslation } from 'react-i18next';
import PhotoGalleryMadera from './PhotoGalleryMadera';

export const Madera = () => {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const listItems2 = t('madera.list1', { returnObjects: true }) as string[];
  return (
    <PageContainer>
      <ContentContainer>
        <Content>
          <Title>{t('hiking.madera')}</Title>
          <BoldText>{t('hiking.destination-madera')}</BoldText>
          <BoldText>{t('hiking.date-madera')}</BoldText>
          <Text> {t('madera.description')}</Text>
          <StyledSubtitle>{t('madera.subtitle0')}</StyledSubtitle>
          <Text> {t('madera.description0')}</Text>
          <StyledSubtitle>{t('madera.subtitle1')}</StyledSubtitle>
          <Text>{t('madera.description1')}</Text>

          <StyledList>
            {listItems2.map((item: string, index: number) => (
              <StyledLi key={index}>
                <Content>{item}</Content>
              </StyledLi>
            ))}
          </StyledList>
          <StyledSubtitle>{t('madera.subtitle2')}</StyledSubtitle>

          <Text>{t('madera.description2')}</Text>
          <StyledSubtitle>{t('madera.subtitle3')}</StyledSubtitle>

          <Text>{t('madera.description3')}</Text>
          <StyledCallToAction>
            <StyledSubtitle> {t('madera.cta')}</StyledSubtitle>
          </StyledCallToAction>

          <PhotoGalleryMadera />
        </Content>
      </ContentContainer>
    </PageContainer>
  );
};
