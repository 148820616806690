import styled from 'styled-components';
import backgroundImage from '../../../../assets/images/forest.webp';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100vw;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
  width: 70%;
  margin: 50px auto;
  gap: 30px;
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 840px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 20px auto 70px auto;
  gap: 30px;

  @media (max-width: 840px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  height: 100%;
`;

export const UpcommingTreksContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 70px;
`;

export const Text = styled.p`
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
  text-align: center;
  width: 70%;
  margin: 5px 0 5px 0;
  @media (max-width: 840px) {
    width: 100%;
    padding: 0 15px;
  }
`;
export const TextSteps = styled.p`
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
  text-align: start;
  margin-top: 10px;
  @media (max-width: 840px) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const TextBold = styled.p`
  font-weight: 800;
  font-size: 1.2em;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
  text-align: center;
  width: 70%;
  margin-top: 10px;
  @media (max-width: 840px) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 2em;
  line-height: 1.5em;
  letter-spacing: 0.1rem;
  margin: 0 15px;
  width: 70%;
`;

export const Subtitle = styled.h2`
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.1rem;
  font-size: 1.6em;
  margin: 0 15px 30px 15px;
`;

export const TrekContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  margin-top: 5px;

  @media (max-width: 840px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const TrekContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.5em;
  width: 70%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  @media (max-width: 840px) {
    flex-direction: column;
    padding-top: 50px;
    width: 100%;
  }
`;
export const DescriptionBox = styled.div`
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 840px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Description = styled.div`
  padding: 5px 15px 10px 15px;
`;

export const DescriptionSmall = styled.div`
  padding: 10px 15px 10px 15px;
  font-size: 0.8em;
  line-height: 1.5em;
`;

export const ButtonStyled = styled.button`
  min-width: 200px;
  font-weight: 900;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  box-shadow: 5px 5px 30px 1px rgba(0, 0, 0, 0.2);
  transition:
    background-color 0.1s ease,
    transform 0.1s ease;

  &:hover {
    background-color: #af9483;
  }
  &:focus {
    background-color: #af9483;
  }
  &:disabled {
    background-color: rgba(189, 189, 189, 0.7);
    color: white;
    box-shadow: none;
  }
  @media (max-width: 840px) {
    margin-top: 10px;
    width: 80%;
  }
`;

export const StyledList = styled.ol`
  font-size: 1em;
  margin-left: 30px;
  background: none;
  background-color: none;
  width: 70%;
  @media (max-width: 840px) {
    margin-top: 10px;
    width: 80%;
  }
`;
