import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes } from './routes/routes';
import { Topbar } from './components/topbar/Topbar';
import { Footer } from './components/footer/Footer';
import { MainContainer } from './App.styles';
import { Suspense } from 'react';

const App = () => {
  return (
    <Router>
      <MainContainer>
        <Topbar />

        <Routes>
          {routes.map((route, title) => (
            <Route
              key={title}
              path={route.href}
              element={<route.component />}
            />
          ))}
        </Routes>
        <Footer />
      </MainContainer>
    </Router>
  );
};

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  );
}
