import styled from 'styled-components';
import { Form, Field, ErrorMessage } from 'formik';

export const FieldStyled = styled(Field)`
  padding: 8px;
  flex-grow: 1;
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 20px 30px 20px;
  margin-top: 20px;
  min-width: 150px;
  @media (max-width: 840px) {
    margin-top: 10px;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const NamesStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ErrorStyled = styled(ErrorMessage)`
  font-size: 10px;
  color: red;
  margin-top: 6px;
`;

export const AdditionalInfoStyled = styled.p`
  font-size: 12px;
`;
