import React, { useLayoutEffect } from 'react';

import {
  Content,
  ContentContainer,
  MainContainer,
  Title,
  ParagrafTitle,
  StyledList,
} from './PrivacyPolicy.styles';
import { useTranslation } from 'react-i18next';

const EmailConfirmation = () => {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const listItems1 = t('policy.list1', { returnObjects: true }) as string[];
  const listItems2 = t('policy.list2', { returnObjects: true }) as string[];
  const listItems3 = t('policy.list3', { returnObjects: true }) as string[];
  const listItems4 = t('policy.list4', {
    returnObjects: true,
  }) as string[];

  return (
    <MainContainer>
      <ContentContainer>
        <Title>{t('policy.paragraf-title1')}</Title>
        <Content>{t('policy.section1')}</Content>
        <ParagrafTitle>{t('policy.paragraf-title2')}</ParagrafTitle>
        <Content>{t('policy.section2a')}</Content>
        <Content>{t('policy.section2b')}</Content>
        <ParagrafTitle>{t('policy.paragraf-title3')}</ParagrafTitle>
        <Content>{t('policy.section3')}</Content>
        <StyledList>
          {listItems1.map((item: string, index: number) => (
            <li key={index}>
              <Content>{item}</Content>
            </li>
          ))}
        </StyledList>
        <ParagrafTitle>{t('policy.paragraf-title4')}</ParagrafTitle>
        <Content>{t('policy.section4a')}</Content>
        <StyledList>
          {listItems2.map((item: string, index: number) => (
            <li key={index}>
              <Content>{item}</Content>
            </li>
          ))}
        </StyledList>
        <Content>{t('policy.section4b')}</Content>
        <StyledList>
          {listItems3.map((item: string, index: number) => (
            <li key={index}>
              <Content>{item}</Content>
            </li>
          ))}
        </StyledList>
        <ParagrafTitle>{t('policy.paragraf-title5')}</ParagrafTitle>
        <Content>{t('policy.section5')}</Content>
        <ParagrafTitle>{t('policy.paragraf-title6')}</ParagrafTitle>
        <Content>{t('policy.section6a')}</Content>
        <StyledList>
          {listItems4.map((item: string, index: number) => (
            <li key={index}>
              <Content>{item}</Content>
            </li>
          ))}
        </StyledList>
        <Content>{t('policy.section6b')}</Content>
        <Content>{t('policy.section6c')}</Content>
      </ContentContainer>
    </MainContainer>
  );
};

export default EmailConfirmation;
