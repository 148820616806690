import React from 'react';

import { StyledGallery, ImageWrapper, CroppedImage } from './Hikes.styles';
import { images } from './images';

export const PhotoGallery = () => {
  return (
    <div>
      <StyledGallery>
        {images.map((src, index) => (
          <ImageWrapper key={index}>
            <CroppedImage src={src} />
          </ImageWrapper>
        ))}
      </StyledGallery>
    </div>
  );
};

export default PhotoGallery;
