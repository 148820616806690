import React from 'react';
import ReactDOM from 'react-dom/client';
import { GlobalStyle } from './index.styles';
import App from './App';
import 'normalize.css';
import WebFont from 'webfontloader';

import './i18n';

WebFont.load({
  google: {
    families: ['Inter:100,200,300,400,500,600,700,800,900'],
  },
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
);
