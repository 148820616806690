import React, { useRef } from 'react';

import { useClickAway } from 'react-use';
import { Squash as Hamburger } from 'hamburger-react';
import { AnimatePresence, motion } from 'framer-motion';
import { routes } from '../../routes/routes';
import { NavMobileContainer, NavLink, StyledList } from './NavMobile.styles';
import { useLocation } from 'react-router-dom';
import { Logo } from './Logo';
import { useTranslation } from 'react-i18next';

interface NavMobileProps {
  isOpen: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}

export const NavMobile: React.FC<NavMobileProps> = ({ isOpen, setOpen }) => {
  const ref = useRef(null);
  const location = useLocation();
  const { t } = useTranslation();

  useClickAway(ref, () => setOpen(false));

  const isHomePage = location.pathname === '/';

  return (
    <NavMobileContainer ref={ref}>
      {!isHomePage && <Logo isVisible={!isOpen} />}
      <Hamburger
        toggled={isOpen}
        size={20}
        toggle={() => setOpen((prev) => !prev)}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{
              duration: 0.1,
            }}
          >
            <StyledList>
              {routes
                .filter((route) => route.showInNav)
                .map((route, idx) => {
                  const { href } = route;
                  const isActive = location.pathname === href;
                  return (
                    <motion.li
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{
                        type: 'spring',
                        stiffness: 280,
                        damping: 20,
                        delay: 0.1 + idx / 15,
                      }}
                      key={route.title}
                    >
                      <NavLink
                        onClick={() => setOpen((prev) => !prev)}
                        href={route.href}
                        isActive={isActive}
                      >
                        <span>{t(route.title)}</span>
                      </NavLink>
                    </motion.li>
                  );
                })}
            </StyledList>
          </motion.div>
        )}
      </AnimatePresence>
    </NavMobileContainer>
  );
};
