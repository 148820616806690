import React from 'react';
// import { Newsletter } from '../home/Newsletter';
import {
  ProjectContainer,
  ContentContainer,
  ProjectTitle,
  ProjectContent,
  DescriptionContainer,
  InnerDescriptionContainer,
  TitleBox,
  ButtonStyled,
  ProcjectBox,
} from './Projects.styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Projects = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ProcjectBox>
      <ProjectContainer background="mountains">
        <ContentContainer>
          <TitleBox />
          <DescriptionContainer>
            <InnerDescriptionContainer>
              <ProjectTitle>{t('projects.hiking')}</ProjectTitle>
              <ProjectContent>{t('projects.h-description1')}</ProjectContent>
              <ProjectContent>{t('projects.h-description2')}</ProjectContent>
              <ButtonStyled
                type="button"
                onClick={() => navigate('/projects/hiking')}
              >
                {t('button.read-more')}
              </ButtonStyled>
            </InnerDescriptionContainer>
          </DescriptionContainer>
        </ContentContainer>
      </ProjectContainer>
      <ProjectContainer background="forest">
        <ContentContainer>
          <DescriptionContainer>
            <InnerDescriptionContainer>
              <ProjectTitle>{t('projects.trekking')}</ProjectTitle>
              <ProjectContent>{t('projects.t-description1')}</ProjectContent>
              <ProjectContent>{t('projects.t-description2')}</ProjectContent>
              <ButtonStyled
                type="button"
                onClick={() => navigate('/projects/trekking')}
              >
                {t('button.read-more')}
              </ButtonStyled>
            </InnerDescriptionContainer>
          </DescriptionContainer>
          <TitleBox />
        </ContentContainer>
      </ProjectContainer>
      {/* <Newsletter /> */}
    </ProcjectBox>
  );
};
