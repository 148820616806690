import styled from 'styled-components';

interface Props {
  type: 'success' | 'error';
}

export const MessageContainer = styled.div<Props>`
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  min-height: 38px;
  width: 450px;
  background-color: ${(props) =>
    props.type === 'error' ? '#ff4d4f' : '#4cbb17'};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  box-shadow: 5px 5px 30px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
`;

export const MessageContent = styled.div`
  color: white;
  font-weight: bold;
  font-size: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
`;
