import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { LogoContainer } from './Logo.styles';
import logoIcon from '../../assets/images/logo.png';
import logoIconBasic from '../../assets/images/logo_basic.png';

interface LogoProps {
  isVisible?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ isVisible = true }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });

  if (!isVisible) return null;

  return (
    <LogoContainer>
      <img
        src={isTabletOrMobile ? logoIconBasic : logoIcon}
        alt="logo"
        height="96"
      />
    </LogoContainer>
  );
};
