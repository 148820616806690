import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  height: 900px;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  height: 60%;
  width: 100%;
  gap: 30px;
  @media (max-width: 840px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 75%;
  }
`;

export const Title = styled.h2`
  color: black;
  font-size: 2rem;
  font-weight: 900;
  margin: 0 20px 0 20px;
`;
export const Content = styled.p`
  color: black;
  margin: 20px 20px 0 20px;
`;
