import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ContentContainer,
  PageContainer,
  Content,
  Title,
  Text,
  StyledSubtitle,
  BoldText,
  StyledImage,
  ButtonStyled,
  StyledCallToAction,
  StyledTextCallToAction,
} from './Hikes.styles';
import { useTranslation } from 'react-i18next';
import { PhotoGallery } from './PhotoGallery';

export const Tatry = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <ContentContainer>
        <Content>
          <Title>{t('hiking.tatry')}</Title>
          <BoldText>{t('hiking.destination-tatry')}</BoldText>
          <BoldText>{t('hiking.date-tatry')}</BoldText>
          <StyledImage
            src="/images/tatry8.webp"
            width="100%"
            height="auto"
            alt="Mountains"
          />
          <Text>{t('tatry.description')}</Text>
          <StyledSubtitle>{t('tatry.subtitle1')}</StyledSubtitle>
          <Text>{t('tatry.description1')}</Text>
          <Text>{t('tatry.description2')}</Text>
          <StyledSubtitle>{t('tatry.subtitle3')}</StyledSubtitle>
          <Text>{t('tatry.description3')}</Text>
          <Text>{t('tatry.description4')}</Text>
          <StyledSubtitle>{t('tatry.subtitle5')}</StyledSubtitle>
          <Text>{t('tatry.description5')}</Text>
          <Text>{t('tatry.description6')}</Text>
          <StyledSubtitle>{t('tatry.subtitle7')}</StyledSubtitle>
          <Text>{t('tatry.description7')}</Text>
          <p>...........</p>
          <Text>{t('tatry.description9')}</Text>
          <StyledSubtitle>{t('tatry.subtitle8')}</StyledSubtitle>
          <Text>{t('tatry.description8')}</Text>
          <StyledCallToAction>
            <StyledTextCallToAction>
              {t('tatry.description10')}
            </StyledTextCallToAction>
            <ButtonStyled
              type="button"
              onClick={() => navigate('/projects/hiking/madera')}
            >
              {t('tatry.description11')}
            </ButtonStyled>
          </StyledCallToAction>
          <PhotoGallery />
          <StyledCallToAction>
            <StyledTextCallToAction>
              {t('tatry.description12')}
            </StyledTextCallToAction>
            <ButtonStyled
              type="button"
              onClick={() => navigate('/projects/hiking/madera')}
            >
              {t('tatry.description11')}
            </ButtonStyled>
          </StyledCallToAction>
        </Content>
      </ContentContainer>
    </PageContainer>
  );
};
