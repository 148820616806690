import React, { useState } from 'react';
import { NavMobile } from './NavMobile';
import { NavDesktop } from './NavDesktop';
import { TopbarContainer, StyledNavMobile } from './Topbar.styles';
import { LanguageSwitcher } from './LanguageSwitcher';

export const Topbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TopbarContainer>
      <NavDesktop />
      <StyledNavMobile>
        <NavMobile isOpen={isOpen} setOpen={setIsOpen} />
      </StyledNavMobile>
      <LanguageSwitcher isOpen={isOpen} />
    </TopbarContainer>
  );
};
