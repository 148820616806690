import styled from 'styled-components';
import backgroundAboutImage from '../../../assets/images/backgroundAbout.webp';

export const AboutContainer = styled.div`
  display: flex;
  min-height: 1200px;
  width: 100vw;
  background-image: url(${backgroundAboutImage});
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 440px) {
    min-height: 1400px;
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: -30px;
  left: 40px;
`;

export const Paragraph = styled.p`
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 3px;
`;

export const Title = styled.h2`
  margin-top: 60px;
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 3px;
`;

export const Subtitle = styled.h3`
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 900;
  letter-spacing: 3px;
`;

export const Text = styled.p`
  margin-top: 10px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  margin: 0 auto;
  height: 60%;
  width: 70%;
  gap: 30px;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 840px) {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AboutUsContainer = styled.div`
  display: flex;
  gap: 100px;
  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const ChildDiv = styled.div`
  flex: 1;
`;
