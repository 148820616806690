import React from 'react';
import { routes } from '../../routes/routes';
import { NavContainer, NavLink } from './NavDesktop.styles';
import { useLocation } from 'react-router-dom';
import { Logo } from './Logo';
import { useTranslation } from 'react-i18next';

export const NavDesktop = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <NavContainer>
      {!isHomePage && <Logo />}
      {routes
        .filter((route) => route.showInNav)
        .map(({ href, title }) => {
          const isActive = location.pathname === href;
          return (
            <p key={title}>
              <NavLink to={href} isActive={isActive}>
                {t(title)}
              </NavLink>
            </p>
          );
        })}
    </NavContainer>
  );
};
