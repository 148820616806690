import styled from 'styled-components';

type NavLinkProps = {
  isActive: boolean;
};

export const NavMobileContainer = styled.div`
  display: none;
  @media (max-width: 600px) {
    flex-direction: column;
    display: flex;
    color: black;
  }
`;

export const NavLink = styled.a<NavLinkProps>`
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.5em;
  margin-left: 30px;
  transition:
    color 0.1s ease,
    transform 0.1s ease;

  &:hover {
    color: black;
    text-decoration: underline;
  }
  &:focus {
    color: #af9483;
    text-decoration: underline;
  }
  color: ${(props) => props.isActive && 'black'};
  text-decoration: ${(props) => props.isActive && 'underline'};
`;

export const StyledList = styled.ul`
  list-style: none;
`;
