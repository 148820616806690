import React, { useLayoutEffect } from 'react';
import {
  Content,
  ContentContainer,
  PageContainer,
  Title,
  BoldText,
  Text,
  StyledSubtitle,
  ButtonStyled,
  StyledCallToAction,
  StyledTextCallToAction,
} from './Hikes.styles';
import { useTranslation } from 'react-i18next';
import PhotoGalleryMalaga from './PhotoGalleryMalaga';
import { useNavigate } from 'react-router-dom';

export const Malaga = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer>
      <ContentContainer>
        <Content>
          <Title>{t('hiking.malaga')}</Title>
          <BoldText>{t('hiking.destination-malaga')}</BoldText>
          <BoldText>{t('hiking.date-malaga')}</BoldText>
          <StyledSubtitle>{t('malaga.subtitle0')}</StyledSubtitle>
          <Text> {t('malaga.description0')}</Text>
          <StyledSubtitle>{t('malaga.subtitle2')}</StyledSubtitle>
          <Text>{t('malaga.description')}</Text>
          <StyledSubtitle>{t('malaga.subtitle3')}</StyledSubtitle>
          <Text>{t('malaga.description1')}</Text>
          <StyledSubtitle>{t('malaga.subtitle4')}</StyledSubtitle>
          <Text>{t('malaga.description2')}</Text>
          <StyledSubtitle>{t('malaga.subtitle5')}</StyledSubtitle>
          <Text>{t('malaga.description3')}</Text>
          <StyledSubtitle>{t('malaga.subtitle6')}</StyledSubtitle>
          <Text>{t('malaga.description4')}</Text>
          <Text>{t('malaga.description5')}</Text>
          <p>...........</p>

          <StyledSubtitle>{t('tatry.subtitle8')}</StyledSubtitle>
          <Text>{t('tatry.description8')}</Text>

          <PhotoGalleryMalaga />
          <StyledCallToAction>
            <StyledTextCallToAction>
              {t('malaga.description12')}
            </StyledTextCallToAction>
            <ButtonStyled
              type="button"
              onClick={() => navigate('/projects/hiking/madera')}
            >
              {t('malaga.description11')}
            </ButtonStyled>
          </StyledCallToAction>
        </Content>
      </ContentContainer>
    </PageContainer>
  );
};
