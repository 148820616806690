import React, { useLayoutEffect } from 'react';

import {
  StyledContainer,
  StyledConatinerForNewsletter,
  StyledConatinerForNewsletterDescription,
  StyledNewsletter,
  Box,
  TitleStyled,
  DescriptionStyled,
  AdditionalInfoStyled,
} from './Newsletter.styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Newsletter: React.FC = () => {
  const { t } = useTranslation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledContainer>
      <StyledConatinerForNewsletterDescription>
        <Box>
          <TitleStyled>Dołącz do nas!</TitleStyled>
          <DescriptionStyled>{t('newsletter.description')}</DescriptionStyled>
          <AdditionalInfoStyled>
            Dane osobowe w związku z subskrypcją newslettera są przetwarzane
            zgodnie z zasadami opisanymi w&nbsp;
            <Link
              to="https://hikematters.com/policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              polityce prywatności.
            </Link>
          </AdditionalInfoStyled>
          <AdditionalInfoStyled>
            Informacje o spacerach i wyprawach w góry prześlemy e-mailem po
            wypełnieniu formularza. Administratorem danych osobowych jest Hike
            Matters osoby fizyczne Katarzyna Przybył i Liliana Stemplewska.
          </AdditionalInfoStyled>
          <AdditionalInfoStyled>
            Dane podane w formularzu Newsletter będą przetwarzane w celu
            otrzymania wiadomości email z informacjami o rozpoczęciu zapisów na
            trekkingi a takze informacji o wyprawach w góry. Podanie danych jest
            dobrowolne, ale niezbędne, aby otrzymać informacje. Przetwarzanie
            danych odbywa się za Twoją zgodą, którą możesz cofnąć w dowolnym
            momencie. Dane te będą przetwarzane do czasu wycofania zgody.
          </AdditionalInfoStyled>
        </Box>
      </StyledConatinerForNewsletterDescription>
      <StyledConatinerForNewsletter>
        <StyledNewsletter src="https://cdn.forms-content-1.sg-form.com/7df44688-9a1a-11ef-ae30-560a1d640c5d" />
      </StyledConatinerForNewsletter>
    </StyledContainer>
  );
};
