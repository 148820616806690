import styled from 'styled-components';

export const TopbarContainer = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const StyledNavMobile = styled.div`
  display: flex;
  justify-content: start;
`;
