import styled from 'styled-components';
import backgroundImage from '../../../assets/images/background.webp';

export const HomeContainer = styled.div`
  display: flex;
  height: 1150px;
  width: 100vw;
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  height: 60%;
  width: 100%;
  gap: 30px;
  @media (max-width: 840px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 75%;
  }
`;

export const IdeaTitle = styled.h2`
  color: black;
  font-size: 2rem;
  font-weight: 900;
  margin: 0 20px 0 20px;
`;
export const IdeaContent = styled.p`
  color: black;
  margin: 20px 20px 0 20px;
`;

export const TitleContainer = styled.div`
  margin-top: 10px;
  font-size: 4rem;
  font-weight: 900;
  letter-spacing: 0.3rem;
  color: black;
  max-width: 500px;
  min-width: 250px;
  @media (max-width: 840px) {
    font-size: 3rem;
    max-width: 100%;
    min-width: 250px;
  }
`;

export const Subtitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
`;

export const DescriptionContainer = styled.div`
  max-width: 500px;
  min-width: 250px;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
  @media (max-width: 840px) {
    max-width: 760px;
    min-width: 250px;
  }
`;

export const TitleBox = styled.div`
  letter-spacing: 0.05rem;
  margin: 0 20px 0 20px;
`;
