import styled from 'styled-components';
import { Field, Form } from 'formik';

export const TextBold = styled.p`
  font-weight: 800;
  margin-top: 10px;
  letter-spacing: 0.05rem;
`;
export const TitleBold = styled.h3`
  font-weight: 800;
  margin-top: 30px;
  letter-spacing: 0.05rem;
`;

export const FieldStyled = styled(Field)`
  padding: 8px;
  flex-grow: 1;
`;
export const TextareaStyled = styled(Field)`
  padding: 8px;
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 60px;
  @media (max-width: 840px) {
    margin-top: 10px;
  }
`;

export const ButtonStyled = styled.button`
  margin-top: 10px;
  font-weight: 900;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  box-shadow: 5px 5px 30px 1px rgba(0, 0, 0, 0.2);
  transition:
    background-color 0.1s ease,
    transform 0.1s ease;

  &:hover {
    background-color: #af9483;
  }
  &:focus {
    background-color: #af9483;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  width: 100vw;
  position: relative;
  margin-bottom: 10vh;
  height: 100vh;
`;

export const Logo = styled.div`
  position: absolute;
  top: -30px;
  left: 40px;
`;

export const Paragraph = styled.p`
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 3px;
`;

export const Title = styled.h2`
  margin-top: 10vh;
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 3px;
`;

export const Text = styled.p`
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 840px) {
    margin: 0 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContactContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
  width: 70%;
  margin: 50px auto;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 840px) {
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 100%;
  }
`;

export const ChildDiv = styled.div`
  width: 100%;
`;
