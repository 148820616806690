import React from 'react';
import { MessageContainer, MessageContent } from './Notification.styles';
import { AnimatePresence, motion } from 'framer-motion';

interface NotificationProps {
  message: string;
  show: boolean;
  type: 'success' | 'error';
}

export const Notification: React.FC<NotificationProps> = ({
  message,
  show,
  type,
}) => {
  if (!show) return null;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <MessageContainer type={type}>
            <MessageContent>{message}</MessageContent>
          </MessageContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
