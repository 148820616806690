import React from 'react';
import {
  AboutContainer,
  ContentContainer,
  Content,
  Title,
  Subtitle,
  Text,
  AboutUsContainer,
  ChildDiv,
} from './About.styles';
import { useTranslation } from 'react-i18next';

export const About = () => {
  const { t } = useTranslation();
  return (
    <AboutContainer>
      <ContentContainer>
        <Content>
          <div>
            <Title>{t('about.title1')}</Title>
            <Text>{t('about.description1')}</Text>
          </div>

          <AboutUsContainer>
            <ChildDiv>
              <Subtitle>{t('about.subtitle1')}</Subtitle>
              <Text>{t('about.description2')}</Text>
            </ChildDiv>
            <ChildDiv>
              <Subtitle>{t('about.subtitle2')}</Subtitle>
              <Text>{t('about.description3')}</Text>
            </ChildDiv>
          </AboutUsContainer>
        </Content>
      </ContentContainer>
    </AboutContainer>
  );
};
